import styled from 'styled-components'

export const StyledMeetingsView = styled.main`
  @media screen {
    width: 100%;

    &:not(.print) {
      padding-top: 6.5rem;

      @supports (padding: env(safe-area-inset-bottom)) {
        padding-bottom: env(safe-area-inset-bottom);
      }
    }
  }
`
