import { useState } from 'react'
import type { FormEvent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useTranslation from 'next-translate/useTranslation'

import toast from 'react-hot-toast'

import { useAuth } from '../../contexts/auth'

import { apiAxios, manadsbladetAPI } from '../../utils/API'

import Modal from '../Modal'
import { StyledBugReport } from './styles'
import { OverlayContainer } from '@react-aria/overlays'

interface BugReportProps {
  onClose(): void
}

function BugReport({ onClose }: BugReportProps) {
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [loading, setLoading] = useState(false)

  const { token, user } = useAuth()

  const { t } = useTranslation('bugs')

  async function sendReport(ev: FormEvent<HTMLFormElement>) {
    ev.preventDefault()

    if (!apiAxios.defaults.headers.common.Authorization) {
      if (user) {
        manadsbladetAPI.addAuthTokenToHeaders(token)
      }
    }

    try {
      setLoading(true)

      const response = await manadsbladetAPI.sendBugReport({
        title,
        description,
        // hjId: window.hj
        //   ? window.hj.globals.get('userId').split('-').shift()
        //   : undefined,
      })

      if (response) {
        onClose()

        toast.success('Sådär! Din felrapport är inskickad!')
      }
    } catch (err) {
      console.error('Error when sending bug report!', err)
      toast.error(() => (
        <div>
          <b>Hmm… Något gick snett med din felrapport.</b>
          <p>Försök igen vid ett senare tillfälle.</p>
        </div>
      ))
    } finally {
      setLoading(false)
    }
  }

  return (
    <OverlayContainer>
      <Modal title={t('report bug')} isOpen onClose={onClose} isDismissable>
        <StyledBugReport>
          {/* <h2>{t('report bug')}</h2> */}
          <form onSubmit={sendReport}>
            <div className='input-group'>
              <label htmlFor='bug-title'>{t('title')}</label>
              <input
                type='text'
                id='bug-title'
                placeholder={t('enter title')}
                value={title}
                onChange={e => {
                  setTitle(e.target.value)
                }}
              />
            </div>
            <div className='input-group'>
              <label htmlFor='bug-description'>{t('description')}</label>
              <textarea
                id='bug-description'
                placeholder={t('enter description')}
                rows={3}
                value={description}
                onChange={e => {
                  setDescription(e.target.value)
                }}
              />
            </div>
            <button type='submit' className='btn'>
              {loading ? (
                <FontAwesomeIcon
                  icon={['far', 'spinner-third']}
                  spin={true}
                  size='lg'
                  color='#fff'
                />
              ) : null}
              {loading ? t('sending') : t('send report')}
            </button>
          </form>
        </StyledBugReport>
      </Modal>
    </OverlayContainer>
  )
}

export default BugReport
