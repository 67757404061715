import {
  useState,
  useEffect,
  createContext,
  useContext,
  ChangeEvent,
  useRef,
  useMemo,
} from 'react'

import dynamic from 'next/dynamic'
import Head from 'next/head'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import useTranslation from 'next-translate/useTranslation'

import { getGardenCareGroup, getCleaningGroup } from '../../../helpers'

import { getMeeting, getNextMeeting } from './utils'

import { Menu, Meeting } from '../../../components'

import {
  MenuList,
  MenuListFooter,
  MenuListItem,
} from '../../../components/Menu/styles'
import { toggleMenu } from '../../../components/Menu'
import { MenuLink } from '../../../components/Menu/MenuLink'

import {
  Header,
  AppBar,
  TabBar,
  Tab,
} from '../../../components/dashboard/Header'

import OtherView from './OtherView'

import { StyledMeetingsView } from './styles'
import { useAuth } from '../../../contexts/auth'
import {
  IProgramme,
  IZoomLinks,
  MEETING_TYPES,
} from '../../../declarations/interfaces.d'
import { StyledMeeting } from '../../../components/dashboard/Meeting/styles'
import Banner from '../../../components/Banner/Banner'

const BugReport = dynamic(() => import('../../../components/BugReport'))

// const TourGuideProvider = dynamic(() => import('./TourGuide'))
// import { useTour } from '@reactour/tour'
// import {
//   tourGuideSettingKey,
//   tourGuideSettingKeyBase,
//   tourSteps,
// } from './TourGuide'
import { formatWeek, getWeekNumber } from '../../../utils/date'
import { useButton } from '@react-aria/button'
import { useMessages } from '../../../contexts/messages'
import { useServiceMeetings } from './use-service-meetings.hooks'
import {
  startOfWeek,
  getLocalTimeZone,
  // endOfWeek,
  today,
  parseAbsoluteToLocal,
  toCalendarDate,
  isToday,
} from '@internationalized/date'
import { getAllNamesInProgramme } from '../Search/algorithm'
// import { useRouter } from 'next/router'

export enum PROGRAMME_VIEWS {
  MEETING = 'meeting',
  OTHER = 'other',
}

const zoomLinks: IZoomLinks = {
  meetings: {
    midweek: '',
    weekend: '',
  },
  service_meetings: {
    general: '',
  },
}

export const ZoomLinksContext = createContext(zoomLinks)
export const useZoomLinksContext = () => useContext(ZoomLinksContext)

function EmptyMeetingView({
  week,
  meetingType,
}: {
  week: number
  meetingType: string
}) {
  const { t } = useTranslation('views')

  return (
    <StyledMeeting>
      <Banner
        icon='info-circle'
        iconType='fad'
        inline={true}
        style={{ marginTop: '2rem' }}
      >
        <h2>
          <strong>{t('meetings.empty')}</strong>
        </h2>
        <p>{t('meetings.no meetings available')}</p>
        <p>
          <em>
            {t('meetings.no meeting found for week', {
              week,
              meetingType: t('meetings.' + meetingType),
            })}
          </em>
        </p>
      </Banner>
    </StyledMeeting>
  )
}

interface MeetingsViewProps {
  programme: IProgramme
}

export default function MeetingsView({ programme }: MeetingsViewProps) {
  // console.log('MeetingsView re-render', performance.now())
  const {
    congregation,
    notes = [],
    service_meetings = [],
    meetings = [],
    files = [],
  } = programme

  const [weekOffset, setWeekOffset] = useState(0)

  /** Get the week number of the currently selected page index.
   * Fallback to current date of no meeting found.
   *
   * ? Perhaps we should paginate weeks by date ranges, and reflect it in the URL.
   * For example: /programme?week=20220523-20220529
   * That way we always know which week we are at, even if there are no meetings.
   * It would also make it easier to paginate and see service meetings before and after the meetings interval.
   */
  const weekStartDate = startOfWeek(today(getLocalTimeZone()), 'sv-SE')
  // const weekEndDate = endOfWeek(weekStartDate, 'sv-SE')

  const pagedWeekStartDate =
    weekOffset !== null
      ? weekStartDate.add({ weeks: weekOffset })
      : weekStartDate

  /** Week number of currently pagination */
  const week = getWeekNumber(pagedWeekStartDate.toDate(getLocalTimeZone()))
  const nextMeeting = getNextMeeting({
    weekStartDate: pagedWeekStartDate,
    meetings: programme.meetings,
  })
  const isServiceMeetingToday = service_meetings.some(sm =>
    isToday(
      toCalendarDate(parseAbsoluteToLocal(sm.timestamp)),
      getLocalTimeZone(),
    ),
  )

  const [view, setView] = useState(
    isServiceMeetingToday
      ? PROGRAMME_VIEWS.OTHER
      : nextMeeting
      ? PROGRAMME_VIEWS.MEETING
      : PROGRAMME_VIEWS.OTHER,
  )
  const [meetingType, setMeetingType] = useState(nextMeeting?.type)

  const meeting = getMeeting({
    weekStartDate: pagedWeekStartDate,
    meetings,
    meetingType,
  })

  // const { setIsOpen } = useTour()
  const [showBugReport, setShowBugReport] = useState(false)

  const [serviceGroup, setServiceGroup] = useState(
    programme.congregation.groups?.[0] ?? null,
  )
  const [myName, setMyName] = useState('')
  const allNamesInMeetingsAndServiceMeetings = useMemo(
    () => getAllNamesInProgramme(programme),
    [programme],
  )

  const { t } = useTranslation('common')
  // const router = useRouter()

  const { user, customClaims } = useAuth()

  const { MessagesToRender } = useMessages()

  // const currentWeekNumberFromURL = router.query.week

  // // Show guided tour if first visit
  // useEffect(() => {
  //   const actionTakenValue = localStorage.getItem(tourGuideSettingKey)
  //   console.log({ actionTakenValue })

  //   if (actionTakenValue === null) {
  //     console.log('setting open true')
  //     setIsOpen(true)
  //   }
  // }, [setIsOpen])

  // Set service group from localStorage
  useEffect(() => {
    const group = localStorage.getItem('manadsbladet.service-group')

    if (group !== null) {
      setServiceGroup(group)
    }
  }, [])

  // Set personal name from localStorage
  useEffect(() => {
    const name = localStorage.getItem('manadsbladet.personal-name')

    if (name !== null) {
      setMyName(name)
    }
  }, [])

  // /** Set current week's to search query */
  // useEffect(() => {
  //   if (router.query.week === undefined) {
  //     const currentWeekNumber = getWeekNumber(new Date())

  //     router.push({
  //       pathname: '/programme',
  //       query: {
  //         week: currentWeekNumber,
  //       },
  //     })
  //   }
  // }, [router])

  // If parent passes new programme data, reset to current week if available?
  // useEffect(() => {
  //   setPage(currentWeekIndex)
  // }, [currentWeekIndex])

  const _serviceMeetingsList = useServiceMeetings({
    weekStartDate: pagedWeekStartDate.toString(),
    service_meetings,
    page: weekOffset,
  })

  const gardenCareGroup = congregation.groups?.length
    ? getGardenCareGroup({
        week,
        startGroup: congregation.garden_group_start,
        groups: congregation.groups,
      })
    : null

  const cleaningGroup = congregation.groups?.length
    ? getCleaningGroup({
        month: pagedWeekStartDate.toDate(getLocalTimeZone()).getMonth(),
        startGroup: congregation.cleaning_group_start,
        groups: congregation.groups,
      })
    : null

  // TODO: Handle empty meetings but with service meetings available. Use ISO date of week start as pagination instead of week number or just index of available data?
  // const weeks = meetings && meetings.length > 1 ? meetings.length / 2 : 0
  let firstWeekAvailableStartDate =
    meetings?.length > 0
      ? startOfWeek(
          toCalendarDate(parseAbsoluteToLocal(meetings[0].date)),
          'sv-SE',
        )
      : weekStartDate
  let lastWeekAvailableStartDate =
    meetings?.length > 0
      ? startOfWeek(
          toCalendarDate(
            parseAbsoluteToLocal(meetings[meetings.length - 1].date),
          ),
          'sv-SE',
        )
      : weekStartDate
  if (service_meetings.length > 0) {
    const firstServiceMeetingWeekStartDate = startOfWeek(
      toCalendarDate(parseAbsoluteToLocal(service_meetings[0].timestamp)),
      'sv-SE',
    )
    const lastServiceMeetingWeekStartDate = startOfWeek(
      toCalendarDate(
        parseAbsoluteToLocal(
          service_meetings[service_meetings.length - 1].timestamp,
        ),
      ),
      'sv-SE',
    )

    if (
      firstServiceMeetingWeekStartDate.compare(firstWeekAvailableStartDate) < 0
    ) {
      firstWeekAvailableStartDate = firstServiceMeetingWeekStartDate
    }

    if (
      lastServiceMeetingWeekStartDate.compare(lastWeekAvailableStartDate) > 0
    ) {
      lastWeekAvailableStartDate = lastServiceMeetingWeekStartDate
    }
  }

  let availableWeeks = 0
  if (meetings.length > 0) {
    if (meetings.length > 1) {
      availableWeeks = meetings.length / 2
    } else {
      availableWeeks = 1
    }
  } else if (service_meetings.length > 0) {
    availableWeeks = Array.from(
      new Set(service_meetings.map(sm => getWeekNumber(sm.timestamp))),
    ).length
  } else {
    console.log(
      'There are no meetings and no service meetings',
      meetings,
      service_meetings,
    )
  }

  /**
   * Set week offset to specific number
   * @param week
   */
  function changeWeekOffsetTo(week: number /* , clamp = true */) {
    // if (clamp) {
    //   setPage(Math.max(0, Math.min(_weeks - 1, week)))
    // } else {
    setWeekOffset(week)
    // }
  }

  function setCurrentWeek() {
    changeWeekOffsetTo(0)
  }

  // function startTour() {
  //   toggleMenu(false)
  //   setIsOpen(true)
  // }

  // function finishGuidedTour() {
  //   // Remove previous tour guide settings
  //   const previousSettings = Object.keys(
  //     JSON.parse(JSON.stringify(localStorage)),
  //   )
  //     .filter(key => key.startsWith(tourGuideSettingKeyBase))
  //     .filter(key => key !== tourGuideSettingKey)

  //   if (previousSettings.length > 0) {
  //     for (const key of previousSettings) {
  //       localStorage.removeItem(key)
  //     }
  //   }

  //   // Set new tour guide setting
  //   localStorage.setItem(tourGuideSettingKey, '1')
  //   setIsOpen(false)
  //   toggleMenu(false)
  // }

  function onChangeServiceGroup(groupName: string) {
    // Set service group to localStorage
    localStorage.setItem('manadsbladet.service-group', groupName)
    setServiceGroup(groupName)
  }

  function onChangePersonalName(name: string) {
    // Set personal name to localStorage
    localStorage.setItem('manadsbladet.personal-name', name)
    setMyName(name)
  }

  function changeMeeting(type: MEETING_TYPES) {
    setMeetingType(type)
  }

  function changeView(view: PROGRAMME_VIEWS) {
    document.body.scrollIntoView({
      block: 'start',
      inline: 'nearest',
      behavior: 'smooth',
    })
    setView(view)
  }

  function changeWeek(factor: number) {
    if (weekOffset !== null) {
      changeWeekOffsetTo(weekOffset + factor)
    } else {
      changeWeekOffsetTo(factor)
    }
  }

  function handleServiceGroupChange(ev: ChangeEvent<HTMLSelectElement>) {
    onChangeServiceGroup(ev.target.value)
  }

  function handlePersonalNameChange(ev: ChangeEvent<HTMLSelectElement>) {
    onChangePersonalName(ev.target.value)
  }

  const weekSubtitle = meeting
    ? formatWeek(meeting.date)
    : week
    ? `Vecka ${week}`
    : 'Ingen vecka'

  const isMeeting = view === PROGRAMME_VIEWS.MEETING

  const showBugReportButtonRef = useRef()

  // useButton ensures that focus management is handled correctly,
  // across all browsers. Focus is restored to the button once the
  // dialog closes.
  const { buttonProps: showBugReportButtonProps } = useButton(
    {
      onPress: () => setShowBugReport(true),
    },
    showBugReportButtonRef,
  )

  return (
    <>
      <Head>
        <title>
          {t('app.programme')} &ndash; {t('app.name')}
        </title>
      </Head>
      <Header>
        <Header.AppBar
          title={t('header:meetings')}
          subtitle={weekSubtitle}
          onToggle={toggleMenu}
        >
          {availableWeeks > 0 && weekOffset !== null ? (
            <>
              {/* {page >= availableWeeks ? ( */}
              {pagedWeekStartDate.compare(lastWeekAvailableStartDate) > 0 ? (
                <AppBar.MenuButton
                  icon='arrow-to-left'
                  label={t('header:buttons.go to last week')}
                  title={t('header:buttons.go to last week')}
                  onClick={() => {
                    const daysDiff =
                      lastWeekAvailableStartDate.compare(weekStartDate)
                    const pageIndex = daysDiff / 7

                    changeWeekOffsetTo(pageIndex)
                  }}
                />
              ) : (
                <AppBar.MenuButton
                  icon='arrow-left'
                  label={t('header:buttons.go to previous week')}
                  title={t('header:buttons.go to previous week')}
                  disabled={
                    pagedWeekStartDate.compare(firstWeekAvailableStartDate) <= 0
                  }
                  onClick={() => {
                    changeWeek(-1)
                  }}
                />
              )}
              <AppBar.MenuButton
                icon='home'
                iconType='fas'
                label={t('header:buttons.show current week')}
                title={t('header:buttons.show current week')}
                disabled={weekOffset === 0}
                onClick={setCurrentWeek}
              />
              {pagedWeekStartDate.compare(firstWeekAvailableStartDate) < 0 ? (
                <AppBar.MenuButton
                  icon='arrow-to-right'
                  label={t('header:buttons.go to first week')}
                  title={t('header:buttons.go to first week')}
                  onClick={() => {
                    const daysDiff =
                      firstWeekAvailableStartDate.compare(weekStartDate)
                    const pageIndex = daysDiff / 7

                    changeWeekOffsetTo(pageIndex)
                  }}
                />
              ) : (
                <AppBar.MenuButton
                  icon='arrow-right'
                  label={t('header:buttons.go to next week')}
                  title={t('header:buttons.go to next week')}
                  disabled={
                    pagedWeekStartDate.compare(lastWeekAvailableStartDate) >= 0
                  }
                  onClick={() => {
                    changeWeek(1)
                  }}
                />
              )}
            </>
          ) : null}
        </Header.AppBar>
        <Header.TabBar>
          {true ? (
            <>
              <TabBar.Tab
                isActive={isMeeting && meetingType === 'midweek'}
                onClick={() => {
                  changeView(PROGRAMME_VIEWS.MEETING)
                  changeMeeting(MEETING_TYPES.MIDWEEK)
                }}
              >
                {t('header:tabs.midweek')}
              </TabBar.Tab>
              <TabBar.Tab
                isActive={isMeeting && meetingType === 'weekend'}
                onClick={() => {
                  changeView(PROGRAMME_VIEWS.MEETING)
                  changeMeeting(MEETING_TYPES.WEEKEND)
                }}
              >
                {t('header:tabs.weekend')}
              </TabBar.Tab>
            </>
          ) : null}
          <Tab
            isActive={!isMeeting}
            onClick={() => {
              changeView(PROGRAMME_VIEWS.OTHER)
            }}
          >
            <span className='first-step'>{t('header:tabs.other')}</span>
          </Tab>
        </Header.TabBar>
      </Header>
      <StyledMeetingsView>
        {MessagesToRender}
        <ZoomLinksContext.Provider value={congregation.zoom_links || zoomLinks}>
          {view === PROGRAMME_VIEWS.MEETING ? (
            meeting ? (
              <Meeting
                type={meeting.type}
                date={meeting.date}
                chairman={meeting.chairman}
                sections={meeting.sections}
                myName={myName}
              />
            ) : (
              <EmptyMeetingView week={week} meetingType={meetingType} />
            )
          ) : (
            <OtherView
              serviceMeetingsList={_serviceMeetingsList}
              notes={notes}
              files={files}
              serviceGroup={serviceGroup}
              gardenCareGroup={gardenCareGroup}
              cleaningGroup={cleaningGroup}
              myName={myName}
            />
          )}
        </ZoomLinksContext.Provider>
      </StyledMeetingsView>
      <Menu>
        <MenuList>
          <MenuLink
            href='/programme/print/'
            iconName='print'
            text={t('menu:print')}
          />
          {user && customClaims?.user_role === 'manager' ? (
            <MenuLink
              href='/upload'
              iconName='tools'
              text={t('menu:controlpanel')}
            />
          ) : null}
          <MenuLink
            href='/logout'
            iconName='sign-out'
            text={t('menu:logout')}
          />
        </MenuList>
        <MenuListFooter>
          {/* {tourSteps.length > 0 ? (
              <MenuListItem>
                <button
                  type='button'
                  onClick={startTour}
                >
                  <FontAwesomeIcon icon={['fal', 'question-circle']} />
                  {t('tour:start tour')}
                </button>
              </MenuListItem>
            ) : null} */}
          <MenuListItem>
            <button
              type='button'
              {...showBugReportButtonProps}
              ref={showBugReportButtonRef}
            >
              <FontAwesomeIcon icon={['fal', 'comment-alt-exclamation']} />
              {t('menu:bug report')}
            </button>
          </MenuListItem>
          <MenuListItem className='second-step'>
            <span>
              <label htmlFor='personal-name'>Mitt namn</label>
              <select
                id='personal-name'
                value={myName}
                onChange={handlePersonalNameChange}
              >
                <option value=''>-- Välj ditt namn --</option>
                {allNamesInMeetingsAndServiceMeetings.map(name => (
                  <option key={name} value={name}>
                    {name}
                  </option>
                ))}
              </select>
            </span>
          </MenuListItem>
          {programme.congregation.groups?.length ? (
            <MenuListItem>
              <span>
                <label htmlFor='service-group'>
                  {t('menu:my service group')}
                </label>
                <select
                  id='service-group'
                  value={serviceGroup ?? programme.congregation.groups?.[0]}
                  onChange={handleServiceGroupChange}
                >
                  {programme.congregation.groups.map(serviceGroupName => (
                    <option key={serviceGroupName} value={serviceGroupName}>
                      {serviceGroupName}
                    </option>
                  ))}
                </select>
              </span>
            </MenuListItem>
          ) : null}
          <MenuListItem>
            <span>
              <b>{programme.congregation.name}</b>
            </span>
          </MenuListItem>
          <MenuListItem>
            <span>
              {t('common:app.name')} v{process.env.NEXT_PUBLIC_APP_VERSION}
            </span>
          </MenuListItem>
        </MenuListFooter>
      </Menu>
      {showBugReport ? (
        <BugReport
          onClose={() => {
            setShowBugReport(false)
          }}
        />
      ) : null}
    </>
  )
}
