/* eslint-disable @next/next/no-img-element */
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

import styled from 'styled-components'

import classnames from 'class-names'

const StyledSiteLogo = styled.a`
  display: flex;
  align-items: flex-start;

  color: black;
  text-decoration: none;

  cursor: pointer;

  @media (prefers-color-scheme: dark) {
    color: white;
  }

  &:hover {
    text-decoration: underline;
  }

  img {
    display: none;

    margin-right: 1rem;

    border-radius: 0.25rem;

    &.visible {
      display: block;
    }

    @media (max-width: 72rem) {
      &.hide-sm {
        display: none;
      }
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;

    min-height: 2rem;

    .app-name {
      display: block;

      font-size: 1.25rem;
      font-weight: 700;
      line-height: 1;
    }

    .app-section {
      display: block;

      margin-top: 0.5rem;

      font-size: 1rem;
      line-height: 1.25rem;
    }
  }
`

interface SiteLogoProps {
  withIcon?: boolean
  section?: string
  className?: string
}

export default function SiteLogo({
  withIcon = true,
  section,
  className,
}: SiteLogoProps) {
  const { t } = useTranslation('common')

  return (
    <Link href='/' passHref>
      <StyledSiteLogo>
        <img
          src='/favicon.png'
          alt='Månadsbladets logotyp'
          width={32}
          height={32}
          className={classnames(withIcon && 'visible', className)}
        />
        <div className='text'>
          <span className='app-name'>{t('app.name')}</span>
          {section ? <span className='app-section'>{section}</span> : null}
        </div>
      </StyledSiteLogo>
    </Link>
  )
}
