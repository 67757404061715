import styled from 'styled-components'

export const StyledModalContainer = styled.div`
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .backdrop {
    display: inline-block;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: transparent;

    transition: background 0.2s ease-out;

    &.open {
      background-color: #0007;

      transition: background 0.2s ease-out;
    }
  }
`

export const StyledModal = styled.article`
  width: calc(100% - 2rem);
  max-width: 30rem;

  position: relative;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  padding: 1.5rem;

  color: black;
  background-color: white;

  border-radius: 1rem;

  h2 {
    margin-bottom: 1em;
  }

  @media (prefers-color-scheme: dark) {
    color: white;
    background-color: #333;
  }

  .buttons {
    display: block;

    margin-top: 1.5rem;

    text-align: right;

    .btn + .btn {
      margin-left: 0.5rem;
    }
  }
`
