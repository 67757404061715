// import { CalendarDate } from '@internationalized/date'
import { useEffect, useState } from 'react'

import type { IServiceMeeting } from '../../../declarations/interfaces'

import { getServiceMeetings } from '../../../helpers'

interface UseServiceMeetingsProps {
  weekStartDate: string
  service_meetings: IServiceMeeting[]
  page: number
}

/**
 * Get all service meetings for a certain week number.
 * Also update automatically when paginating between weeks.
 * @returns A list of service meetings
 */
export function useServiceMeetings({
  weekStartDate,
  service_meetings,
  page,
}: UseServiceMeetingsProps) {
  const [serviceMeetingsList, setServiceMeetingsList] = useState(
    getServiceMeetings({
      weekStartDate,
      serviceMeetings: service_meetings,
    }),
  )

  useEffect(() => {
    setServiceMeetingsList(
      getServiceMeetings({
        weekStartDate,
        serviceMeetings: service_meetings,
      }),
    )
  }, [page, service_meetings, weekStartDate])

  return serviceMeetingsList
}
