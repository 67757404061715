import {
  CalendarDate,
  endOfWeek,
  parseAbsoluteToLocal,
  startOfWeek,
  toCalendarDate,
} from '@internationalized/date'

const sundayToMonday = (day: number) => (day === 0 ? 7 : day)

export function formatMonth(datestamp: string) {
  const date = new Date(datestamp)

  return date.toLocaleDateString('sv-SE', { month: 'long', year: 'numeric' })
}

export function formatWeek(datestamp: string) {
  const date = new Date(datestamp)

  date.setDate(date.getDate() - (sundayToMonday(date.getDay()) - 1))

  const endDate = new Date(date.getTime() + 6 * 24 * 60 * 60 * 1000)

  const sameMonth = endDate.getMonth() === date.getMonth()

  const startDateString = formatDate({
    datestamp: date,
    noMonth: sameMonth,
    shortMonth: true,
  })

  const endDateString = formatDate({
    datestamp: endDate,
    shortMonth: !sameMonth,
  })

  return startDateString + '–' + endDateString
}

export function formatDate({
  datestamp,
  shortMonth = false,
  noMonth = false,
}: {
  datestamp: Date | string | number
  shortMonth?: boolean
  noMonth?: boolean
}) {
  const date = new Date(datestamp)

  return date.toLocaleDateString('sv-SE', {
    day: 'numeric',
    ...{ month: !noMonth ? (shortMonth ? 'short' : 'long') : undefined },
  })
}

/**
 * Source: https://weeknumber.net/how-to/javascript
 *
 * Returns the ISO week of the date.
 */
export function getWeekNumber(dateString: Date | string | number) {
  const date = new Date(dateString /*.getTime()*/)
  date.setHours(0, 0, 0, 0)
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7))
  // January 4 is always in week 1.
  const week1 = new Date(date.getFullYear(), 0, 4)
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return (
    1 +
    Math.round(
      ((date.getTime() - week1.getTime()) / 86400000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
        7,
    )
  )
}

/**
 * Find the monday of any given week number
 * @param year Defaults to the current year
 * @returns The ISO date string of the monday of given week (YYYY-MM-dd)
 */
export function getMondayFromWeek(
  weekNumber: number,
  year = new Date().getFullYear(),
) {
  // January 4 is always in week 1.
  const week1 = new Date(year, 0, 4)
  const daysDiff = (weekNumber - 1) * 7

  // Add week diff times 7 days to get correct week
  const targetWeek = new Date(
    week1.getFullYear(),
    week1.getMonth(),
    week1.getDate() + daysDiff,
  )

  const mondayOfTargetWeek = new Date(
    targetWeek.getFullYear(),
    targetWeek.getMonth(),
    targetWeek.getDate() - targetWeek.getDay() + 1,
  )

  return mondayOfTargetWeek.toLocaleDateString(undefined, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })
}

export function isSameWeek(
  date: CalendarDate | string,
  targetDate: CalendarDate,
) {
  const firstDayOfWeek = startOfWeek(targetDate, 'sv-SE')
  const lastDayOfWeek = endOfWeek(targetDate, 'sv-SE')

  if (typeof date === 'string') {
    date = toCalendarDate(parseAbsoluteToLocal(date))
  }

  if (date.compare(firstDayOfWeek) >= 0 && date.compare(lastDayOfWeek) <= 0) {
    return true
  }

  return false
}
