import { ReactNode } from 'react'
import { ITask } from '../../../declarations/interfaces.d'

import Task from '../Task'

import StyledSection from './styles'

type Task = ITask & {
  component?: ReactNode
}

interface SectionProps {
  type: string
  title?: string
  heading?: string
  tasks?: Task[]
  children?: ReactNode
  myName?: string
}

function Section({
  type,
  title,
  heading,
  tasks,
  children,
  myName,
}: SectionProps) {
  return (
    <StyledSection className={`section ${type}`}>
      {title ? (
        <h2 className='section-title'>
          {title} {heading ? <span>({heading})</span> : null}
        </h2>
      ) : null}
      {tasks
        ? tasks
            .filter(task => task !== undefined && task !== null)
            .map((task: Task, index) => (
              <Task
                key={index}
                type={task.type}
                title={task.title}
                assigned={task.assigned}
                congregation={task.congregation}
                reader={task.reader}
                component={task.component}
                myName={myName}
              />
            ))
        : children ?? null}
    </StyledSection>
  )
}

export default Section
