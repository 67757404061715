import {
  IMeeting,
  IProgramme,
  ISection,
  ITask,
} from '../../../declarations/interfaces.d'

export interface SearchResult {
  chairmen: IMeeting[]
  assignees: {
    task: ITask
    section: ISection
    meeting: IMeeting
  }[]
}

export function getAllNamesInProgramme(data: IProgramme): string[] {
  const names = []
  const foreignNames = []

  if (data.meetings) {
    for (const meeting of data.meetings) {
      if (meeting.chairman?.length > 0 && meeting.chairman !== 'Inget möte') {
        names.push(meeting.chairman)
      }

      for (const section of meeting.sections) {
        for (const task of section.tasks) {
          if (task.assigned?.length > 0) {
            if (
              task.congregation &&
              data.congregation.name.startsWith(task.congregation) === false
            ) {
              foreignNames.push(task.assigned)
            }

            names.push(task.assigned)
          }
          if (task.reader?.length > 0) {
            names.push(task.reader)
          }
        }
      }
    }
  }

  if (data.service_meetings) {
    for (const serviceMeeting of data.service_meetings) {
      if (serviceMeeting.assigned?.length > 0) {
        names.push(serviceMeeting.assigned)
      }

      if (
        serviceMeeting.location !== 'Zoom' &&
        serviceMeeting.location !== 'Rikets sal'
      ) {
        names.push(serviceMeeting.location)
      }
    }
  }

  const uniqueNames = Array.from(new Set(names))
    .filter(name => foreignNames.includes(name) === false)
    .sort()

  return uniqueNames
}

export function searchProgramme(name: string, data: IProgramme): SearchResult {
  const lowerCaseName = name.toLowerCase()
  const chairmen = []
  const assignees = []

  if (data.meetings) {
    for (const meeting of data.meetings) {
      if (meeting.chairman.toLowerCase().includes(lowerCaseName)) {
        chairmen.push(meeting)
        continue
      }

      for (const section of meeting.sections) {
        for (const task of section.tasks) {
          if (
            task.assigned?.toLowerCase().includes(lowerCaseName) ||
            task.reader?.toLowerCase().includes(lowerCaseName)
          ) {
            assignees.push({
              task,
              section,
              meeting,
            })
          }
        }
      }
    }
  }

  return {
    chairmen,
    assignees,
  }
}
