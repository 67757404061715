import useTranslation from 'next-translate/useTranslation'
import classNames from 'class-names'
import type { IServiceMeeting } from '../../../declarations/interfaces'

import { useZoomLinksContext } from '../../../views/dashboard/Meetings/Meetings'

import ServiceMeetingItem from './styles'

const niceDate = (date: string | number | Date) =>
  new Date(date).toLocaleDateString('sv-SE', {
    day: 'numeric',
    weekday: 'short',
    month: 'short',
    hour: 'numeric',
    minute: 'numeric',
  })

type ServiceMeetingProps = IServiceMeeting & { myName?: string }

function ServiceMeeting({
  location,
  timestamp,
  assigned,
  myName,
}: ServiceMeetingProps) {
  const zoomLinks = useZoomLinksContext()

  const { t } = useTranslation('zoom')

  const hasServiceMeetingLink =
    Boolean(zoomLinks?.service_meetings?.general) &&
    ['Zoom', 'Rikets sal'].includes(location)

  return (
    <ServiceMeetingItem>
      <section className='info'>
        <p className='date'>{niceDate(timestamp)}</p>
        <p
          className={classNames('location', myName === location && 'highlight')}
        >
          {location}
        </p>
        <p className='assigned'>
          <span className={classNames(myName === assigned && 'highlight')}>
            {assigned}
          </span>
        </p>
      </section>
      {hasServiceMeetingLink ? (
        <aside>
          <a
            href={zoomLinks.service_meetings.general}
            rel='noopener noreferrer'
            target='_blank'
            className='btn btn-default'
          >
            {t('join')}
          </a>
        </aside>
      ) : null}
    </ServiceMeetingItem>
  )
}

export default ServiceMeeting
