import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useAuth } from '../../../contexts/auth'
import { IProgramme } from '../../../declarations/interfaces'
import { apiAxios } from '../../../utils/API'
// import { IFile } from '../../../views/Upload/data'
import PDFLightBox from '../../PDFLightbox'

import StyledFilesList from './styles'

interface FilesListProps {
  files: IProgramme['files']
}

function FilesList({ files }: FilesListProps) {
  const { token } = useAuth()
  const [lightboxFile, setLightboxFile] = useState<{
    url: string
    name: string
  } | null>()

  function previewFile(name: string) {
    if (name.length) {
      // console.log(
      //   apiAxios.defaults.baseURL,
      //   apiAxios.defaults.baseURL + 'files/' + name,
      //   token
      // )
      setLightboxFile({
        url: apiAxios.defaults.baseURL + 'files/' + name,
        name,
      })
    }
  }

  function closeFilePreview() {
    setLightboxFile(null)
  }

  return (
    <>
      <StyledFilesList>
        {files.map(({ name }, fileIdx) => {
          // const fileName = url
          //   .substring(url.lastIndexOf('%2F') + 3)
          //   .split('?')[0]

          return (
            <li key={'file' + fileIdx}>
              <button
                type='button'
                className='btn btn-default'
                onClick={() => {
                  previewFile(name)
                }}
              >
                <FontAwesomeIcon icon={['far', 'file-pdf']} />
                {name}
              </button>
            </li>
          )
        })}
      </StyledFilesList>
      {lightboxFile ? (
        <PDFLightBox
          url={lightboxFile.url}
          fileName={lightboxFile.name}
          token={token}
          onClose={closeFilePreview}
        />
      ) : null}
    </>
  )
}

export default FilesList
