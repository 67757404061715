import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import NavLink, { NavLinkProps } from '../NavLink'
import { MenuListItem } from './styles'

interface MenuLinkProps extends NavLinkProps {
  iconName: IconName
  text: string
}

export function MenuLink({ iconName, text, ...navLinkProps }: MenuLinkProps) {
  return (
    <MenuListItem>
      <NavLink {...navLinkProps}>
        <FontAwesomeIcon icon={['fal', iconName]} fixedWidth={true} />
        {text}
      </NavLink>
    </MenuListItem>
  )
}
