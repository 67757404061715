import useTranslation from 'next-translate/useTranslation'

import Section from '../Section'

import { StyledMeeting } from './styles'

import { useZoomLinksContext } from '../../../views/dashboard/Meetings/Meetings'
import { ISection, MEETING_TYPES } from '../../../declarations/interfaces.d'
import { formatDate } from '../../../utils/date'

interface MeetingProps {
  title?: string
  type: MEETING_TYPES
  date: string
  chairman: string
  sections: ISection[]
  myName?: string
}

function Meeting({
  title,
  type,
  date,
  chairman,
  sections,
  myName,
}: MeetingProps) {
  const zoomLinks = useZoomLinksContext()

  const { t } = useTranslation('zoom')

  const hasMeetingLink = Boolean(zoomLinks?.meetings?.[type])

  return (
    <StyledMeeting>
      {hasMeetingLink && zoomLinks.meetings[type] ? (
        <header className='zoom'>
          <a
            href={zoomLinks.meetings[type]}
            rel='noopener noreferrer'
            target='_blank'
            className='btn btn-primary'
          >
            {t('join meeting')}
          </a>
        </header>
      ) : null}
      <Section
        type='intro'
        tasks={[
          {
            type: 'intro',
            title: title
              ? title.toUpperCase() + ' – ' + formatDate({ datestamp: date })
              : formatDate({ datestamp: date }),
            assigned: chairman,
          },
        ]}
        myName={myName}
      />
      {sections.map((section, index) => (
        <Section
          key={index}
          type={section.type}
          title={section.title}
          heading={section.heading}
          tasks={section.tasks}
          myName={myName}
        />
      ))}
    </StyledMeeting>
  )
}

export default Meeting
