import { ComponentProps, HTMLProps, ReactNode } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { IconName, IconPrefix } from '@fortawesome/fontawesome-common-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { NavigationDrawer } from './styles'
import { StyledMenuButton } from '../dashboard/Header/styles'

import { toggleMenu } from './Menu'
import SiteLogo from '../SiteLogo'

type MenuButtonProps = {
  icon: IconName
  iconType?: IconPrefix
  label: string
} & HTMLProps<HTMLButtonElement> &
  ComponentProps<typeof StyledMenuButton>

export function MenuButton({
  icon,
  label,
  iconType = 'far',
  ...props
}: MenuButtonProps) {
  return (
    <StyledMenuButton type='button' aria-label={label} {...props}>
      <FontAwesomeIcon icon={[iconType, icon]} />
    </StyledMenuButton>
  )
}

type NavigationProps = {
  section?: string

  children?: ReactNode
}

function Navigation({ children, section }: NavigationProps) {
  const { t } = useTranslation('menu')

  return (
    <>
      <aside
        role='dialog'
        className='backdrop'
        onClick={() => {
          toggleMenu()
        }}
        onKeyUp={() => {
          toggleMenu()
        }}
      />
      <NavigationDrawer aria-label='Huvudmeny'>
        <header>
          <MenuButton
            icon='arrow-to-left'
            label={t('close menu')}
            title={t('close menu')}
            onClick={() => {
              toggleMenu()
            }}
            className='menu-toggle'
          />
          <SiteLogo className='hide-sm' section={section} />
        </header>
        {children}
      </NavigationDrawer>
    </>
  )
}

export default Navigation
