import type {
  ValidationResult,
  DateValue,
  DatePickerProps,
} from 'react-aria-components'
import {
  Button,
  Calendar,
  CalendarCell,
  CalendarGrid,
  CalendarGridBody,
  CalendarGridHeader,
  CalendarHeaderCell,
  DateInput,
  DatePicker,
  DateSegment,
  Dialog,
  Group,
  FieldError,
  Heading,
  Label,
  Popover,
  Text,
} from 'react-aria-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons'
import styled from 'styled-components'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import classNames from 'class-names'
import { getLocalTimeZone, isSameDay, today } from '@internationalized/date'

const StyledDatePickerComponent = styled.div`
  position: relative;

  label + div,
  .react-aria-Label + div {
    display: flex;
    /* justify-content: space-between; */
    gap: 0.5rem;
  }
`

interface MyDateFieldProps<T extends DateValue> extends DatePickerProps<T> {
  label?: string
  description?: string
  errorMessage?: string | ((validation: ValidationResult) => string)
}

export function DatePickerAriaComponent<T extends DateValue>({
  label,
  description,
  errorMessage,
  ...props
}: MyDateFieldProps<T>) {
  return (
    <StyledDatePickerComponent>
      <StyledDateField>
        <StyledDatePicker {...props} className='wrapper'>
          <Label>{label}</Label>
          <Group>
            <StyledDateInput className='field-input field input'>
              {segment => <DateSegment segment={segment} className='segment' />}
            </StyledDateInput>
            {description && <Text slot='description'>{description}</Text>}
            <FieldError>{errorMessage}</FieldError>
            <Button className='btn btn-primary btn-icon'>
              <FontAwesomeIcon icon={faCalendarAlt} />
            </Button>
          </Group>
          <Popover>
            <StyledDialog>
              <StyledCalendar>
                <article className='calendar'>
                  <header className='header'>
                    <Heading />
                    <Button slot='previous'>
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </Button>
                    <Button slot='next'>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </Button>
                  </header>
                  <CalendarGrid>
                    <CalendarGridHeader>
                      {day => <CalendarHeaderCell>{day}</CalendarHeaderCell>}
                    </CalendarGridHeader>
                    <CalendarGridBody>
                      {date => {
                        const isToday = isSameDay(
                          date,
                          today(getLocalTimeZone()),
                        )

                        return (
                          <StyledCalendarCell
                            date={date}
                            className={classNames('cell', isToday && 'today')}
                          />
                        )
                      }}
                    </CalendarGridBody>
                  </CalendarGrid>
                </article>
              </StyledCalendar>
            </StyledDialog>
          </Popover>
        </StyledDatePicker>
      </StyledDateField>
    </StyledDatePickerComponent>
  )
}

const StyledDateField = styled.div`
  /* --color: ${props => props.theme.colors.primary.brand}; */
  --color: hsl(214 80% 50%);
  --color_20: hsl(214 80% 50% / 0.2);

  @media (prefers-color-scheme: dark) {
    /* --color: ${props => props.theme.colors.primary.default}; */
    --color: hsl(214 64% 64%);
    --color_20: hsl(214 64% 64% / 0.2);
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .field {
    display: inline-flex;
    cursor: default;
  }

  .segment {
    padding: 1px 1px;
    font-variant-numeric: tabular-nums;
    text-align: end;
  }

  .segment.placeholder {
    color: #bbb;
  }

  .segment:focus {
    color: white;
    background: var(--color);
    outline: none;
    border-radius: 4px;
  }
`

const StyledDateInput = styled(DateInput)`
  .segment[data-type='hour'] {
    margin-left: 0.5ch;
  }
`

const StyledDatePicker = styled(DatePicker)``

const StyledCalendarCell = styled(CalendarCell)`
  &.cell {
    cursor: default;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 2rem;
    height: 2rem;

    font-size: 1rem;

    border: 0;
    border-radius: 2rem;

    color: black;
    background-color: transparent;

    @media (prefers-color-scheme: dark) {
      color: white;
    }
  }

  &.today {
    font-weight: 600;

    color: var(--color);
  }

  &.selected,
  &[data-selected] {
    font-weight: 500;

    color: var(--color);
    background: var(--color_20);

    &.today {
      font-weight: 600;

      color: white;
      background-color: var(--color);
    }
  }

  &.unavailable,
  &[data-unavailable] {
    color: #c00;
  }

  &.disabled,
  &[data-disabled] {
    /* display: none; */
    color: #bbb;
  }
`

const StyledDialog = styled(Dialog)`
  width: 15rem;

  position: absolute;
  z-index: 1;

  margin-top: 0.25rem;
  padding: 0.5rem;

  background: white;

  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem hsl(0 0% 0% / 0.1),
    0 0.5rem 1rem hsl(0 0% 0% / 0.05);

  @media (prefers-color-scheme: dark) {
    color: white;
    background-color: #222;
  }
`

const StyledCalendar = styled(Calendar)`
  /* --color: ${props => props.theme.colors.primary.brand}; */
  --color: hsl(214 80% 50%);
  --color_20: hsl(214 80% 50% / 0.2);

  @media (prefers-color-scheme: dark) {
    /* --color: ${props => props.theme.colors.primary.default}; */
    --color: hsl(214 64% 64%);
    --color_20: hsl(214 64% 64% / 0.2);
  }

  .header {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    margin: 0 0.5rem 0.5rem;
  }

  /* Month and year */
  .header h2 {
    font-size: 1.25rem;
    flex: 1;
    margin: 0;
  }

  .header button {
    padding: 0;
    border: 0;
    background-color: transparent;

    & + button {
      margin-left: 0.5rem;
    }

    svg {
      color: var(--color);
    }
  }

  .calendar table {
    width: 100%;
    border-collapse: collapse;
  }

  .calendar thead {
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
    text-transform: uppercase;
  }
`
