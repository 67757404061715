import styled from 'styled-components'

export const FilesList = styled.ul`
  list-style: none;

  li button {
    display: inline-block;

    padding: 0.5rem 1rem;
    margin-top: 0.5rem;

    color: #28456c;
    background-color: #28456c22;

    /* border-radius: 0.25rem; */

    @media (prefers-color-scheme: dark) {
      color: white;
      background-color: #383838;
    }
  }

  svg {
    margin-right: 0.5rem;
  }
`

export default FilesList
