import styled from 'styled-components'

import { StyledMenuButton } from '../dashboard/Header/styles'

export const NavigationDrawer = styled.nav`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  width: calc(100vw - 3.5rem);
  max-width: 20rem;

  padding: 1rem 0;

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;

  transform: translateX(-100%);
  transition: transform 0.2s ease-out;

  background-color: white;

  .menu-open & {
    transform: translateX(0);
    box-shadow: 0 0 8px #0004;
  }

  @media (prefers-color-scheme: dark) {
    color: white;
    background-color: #1d334f;
  }

  @supports (padding: env(safe-area-inset-top)) {
    max-width: calc(20rem + env(safe-area-inset-left));

    padding-left: env(safe-area-inset-left);
    padding-bottom: env(safe-area-inset-bottom);
  }

  header {
    display: flex;
    align-items: center;

    min-height: 4rem;

    margin-bottom: 0.5rem;
    padding: 1rem 1rem 1.5rem;

    ${StyledMenuButton} {
      background-color: hsl(0 0% 0% / 0.0625);

      @media (prefers-color-scheme: dark) {
        background-color: hsl(0 0% 100% / 0.125);
      }

      &:hover {
        background-color: hsl(0 0% 0% / 0.125);

        @media (prefers-color-scheme: dark) {
          background-color: hsl(0 0% 100% / 0.25);
        }
      }
    }
  }
`

export const StyledMenu = styled.div`
  z-index: 1;

  .backdrop {
    display: inline-block;

    position: fixed;
    top: 0;
    left: 0;

    background-color: transparent;

    transition: background 0.2s ease-out;
  }

  &.menu-open .backdrop {
    right: 0;
    bottom: 0;

    background-color: #0007;

    transition: background 0.2s ease-out;
  }
`

export const MenuListItem = styled.li`
  min-height: 3rem;

  list-style: none;

  padding: 0;

  a,
  button,
  span {
    display: flex;
    align-items: center;

    width: 100%;
    min-height: 2.5rem;

    padding: 0.75rem 1rem;

    line-height: 1.5;
  }

  a,
  button {
    font-size: 1rem;
    font-weight: 500;
    text-decoration: none;

    cursor: pointer;
    color: black;
    background-color: transparent;

    border: 0 solid transparent;

    /** User in controlpanel to indicate changes have been made on a tab */
    &.notice::after {
      content: '';

      align-self: center;
      justify-self: flex-end;

      width: 0.5rem;
      height: 0.5rem;

      margin-left: auto;

      background-color: ${props => props.theme.colors.primary.brand};

      border-radius: 0.5rem;

      @media (prefers-color-scheme: dark) {
        background-color: white;
      }
    }

    svg {
      margin-right: 1rem;

      font-size: 1.5rem;

      color: #5e5e5e;

      transform: translateX(-0.25rem);

      @media (prefers-color-scheme: dark) {
        color: rgba(255, 255, 255, 0.8);
      }
    }

    &:focus,
    &:hover {
      color: #28456c;
      background-color: #28456c22;
      border-color: currentColor;

      svg {
        color: ${props => props.theme.colors.primary.brand_disabled};

        @media (prefers-color-scheme: dark) {
          color: white;
        }
      }

      @media (prefers-color-scheme: dark) {
        color: white;
        background-color: #fff1;
        border-color: hsl(0 0% 100% / 0.25);
      }
    }

    &.active {
      font-weight: 800;
      letter-spacing: 0.0125ch;

      color: ${props => props.theme.colors.primary.icon};
      background-color: ${props => props.theme.colors.primary.default};

      box-shadow: inset 0.25rem 0 ${props => props.theme.colors.primary.brand};

      svg {
        color: ${props => props.theme.colors.primary.icon};
      }

      @media (prefers-color-scheme: dark) {
        color: ${props => props.theme.colors.primary.default};
        background-color: ${props => props.theme.colors.primary.icon};

        box-shadow: inset 0.25rem 0
          ${props => props.theme.colors.primary.default};

        svg {
          color: ${props => props.theme.colors.primary.default};
        }
      }
    }

    @media (prefers-color-scheme: dark) {
      color: white;
    }
  }

  span {
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    label + select {
      margin-top: 0.5rem;
    }

    select {
      width: 100%;
    }
  }

  & + li {
  }
`

export const MenuList = styled.ul``

export const MenuListFooter = styled(MenuList)`
  margin-top: auto;
`

export const MenuButton = styled.button`
  z-index: 4;

  appearance: none;
  -webkit-appearance: none;

  width: 3.5rem;
  height: 3.5rem;

  position: fixed;
  right: 1rem;
  bottom: 1rem;

  padding: 1rem;

  font-size: 1.5rem;
  text-align: center;

  color: white;
  background-color: #28456c;
  box-shadow: 0 2px 4px #0004;

  border: 0;
  border-radius: 1.75rem;

  @media (prefers-color-scheme: dark) {
    .menu-open & {
      background-color: #335789;
    }
  }

  @supports (padding: env(safe-area-inset-bottom)) {
    bottom: calc(1rem + env(safe-area-inset-bottom));
  }

  svg {
    path {
      stroke: white;
    }
  }

  span {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0.25rem;

    font-size: 0.67rem;
    text-align: center;
  }
`
