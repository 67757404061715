import { ReactNode } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { StyledLoadingIndicator } from './styles'

interface IProps {
  text?: string
  children?: ReactNode
}

export function LoadingIndicator({ text, children }: IProps) {
  return (
    <StyledLoadingIndicator aria-busy='true'>
      <FontAwesomeIcon
        icon={['far', 'spinner-third']}
        spin
        size='lg'
        color='#888'
      />
      {text ? <p>{text}</p> : null}
      {children}
    </StyledLoadingIndicator>
  )
}

export default LoadingIndicator
