import styled from 'styled-components'

export const Section = styled.section`
  --c-section: #707070;
  --c-section-text: var(--c-section);

  @media screen and (prefers-color-scheme: dark) {
    --c-section: #a0a0a0;
  }

  .section-title {
    text-transform: uppercase;

    color: var(--c-section-text, #000);

    @media screen and (prefers-color-scheme: dark) {
      /*color: #a0a0a0;*/
    }
  }

  &.intro {
    --c-section: #28456c;

    @media print {
      border-radius: 0.25rem 0.25rem 0.25rem 0;
    }
  }

  &.song {
    --c-section: #888;

    @media print {
      &:last-child {
        border-bottom-left-radius: 0.25rem;
      }
    }

    .title {
      font-weight: normal;
    }
  }

  &.gw,
  &.wt,
  &.talk {
    --c-section: #606970;

    @media screen and (prefers-color-scheme: dark) {
      --c-section-text: #7b858d;
    }
  }

  &.fm {
    --c-section: #c18625;
    --c-section-text: #9e6b18;
    @media screen and (prefers-color-scheme: dark) {
      --c-section-text: var(--c-section);
    }
  }

  &.cl {
    --c-section: #961526;

    @media screen and (prefers-color-scheme: dark) {
      --c-section-text: #ed475d;
    }
  }

  &.co {
    --c-section: #5b3c88;
    --c-section-text: #6b3bb0;

    @media screen and (prefers-color-scheme: dark) {
      --c-section-text: #9f67f0;
    }
  }

  @media screen {
    padding: 1.5rem 1rem;
    margin-top: 1rem;

    background-color: white;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.12),
      inset 0.375rem 0 0 var(--c-section, #000);

    @media screen and (prefers-color-scheme: dark) {
      color: white;
      background-color: #1c1c1c;
    }

    .section-title {
      font-size: 0.75rem;
      margin-bottom: 1rem;

      line-height: 1;
    }

    &.intro,
    &.song {
      padding: 1rem;
    }

    &:last-of-type {
      margin: 1rem 0;
    }
  }

  .print & {
    padding: 3pt 8pt;
    border-left: 5pt solid var(--c-section, #000);

    & + & {
      margin-top: 1px;
    }

    &.intro,
    &.week-info {
      border: 1px solid var(--c-section, #000);
      border-left-width: 5pt;
    }

    &.week-info {
      margin-top: 5pt;

      @media print {
        border-radius: 0.25rem;
      }
    }

    .section-title {
      font-size: 8pt;
      font-weight: normal;
      line-height: 1.8;
    }
  }
`

export default Section
