import styled from 'styled-components'

export const Task = styled.div`
  display: inline-block;
  width: 100%;

  .title,
  .assigned,
  .congregation,
  .reader {
    line-height: 1.5;
  }

  .title {
    font-weight: 600;
  }

  .assigned + .reader {
    width: 100%;
  }

  .assigned,
  .reader {
    float: right;

    text-align: right;
  }

  .assigned span.highlight,
  .reader span.highlight {
    display: inline-block;
  }
  .location.highlight,
  .reader span.highlight,
  .assigned.highlight,
  .assigned span.highlight {
    padding: 0 0.5rem;
    margin-right: -0.5rem;

    font-weight: 600;

    color: white;
    background-color: #28456c;

    border-radius: 0.25rem;

    @media (prefers-color-scheme: dark) {
      color: black;
      background-color: #dae4f1;
    }
  }

  @media print {
    & + & {
      margin-top: 3.5pt;
    }

    .assigned,
    .reader {
      font-style: italic;
    }
  }

  @media screen {
    & + & {
      margin-top: 1rem;
    }
  }
`

export default Task
