import { ReactNode } from 'react'

import Navigation from './NavigationDrawer'

import { StyledMenu } from './styles'

export function toggleMenu(open?: boolean) {
  document.querySelector('#side-menu')?.classList.toggle('menu-open', open)
}

type MenuProps = {
  section?: string

  children?: ReactNode
}

export default function Menu({ children, section }: MenuProps) {
  return (
    <StyledMenu id='side-menu'>
      <Navigation section={section}>{children}</Navigation>
    </StyledMenu>
  )
}
