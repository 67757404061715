import {
  // parseAbsoluteToLocal,
  // CalendarDate,
  // getLocalTimeZone,
  // parseAbsolute,
  // toCalendarDate,
  // parseDateTime,
  parseDate,
} from '@internationalized/date'
import { IServiceMeeting, MEETING_TYPES } from '../declarations/interfaces.d'

import { getWeekNumber, isSameWeek } from '../utils/date'

const meetingTypes = {
  [MEETING_TYPES.MIDWEEK]: 'Veckomötet',
  [MEETING_TYPES.WEEKEND]: 'Helgmötet',
}

export const getMeetingTypeName = (type: MEETING_TYPES) => {
  return meetingTypes[type] || 'Felaktig mötestyp'
}

export function getWeek({
  page,
  startWeek,
  endWeek,
  diff = 0,
}: {
  page: number
  startWeek: number
  endWeek: number
  diff: number
}) {
  const weekIdx = diff + page

  return weekIdx + startWeek <= 52
    ? weekIdx + startWeek
    : Math.min(weekIdx + startWeek - 52, endWeek)
}

export function getServiceMeetings({
  weekStartDate,
  serviceMeetings = [],
}: {
  weekStartDate: string
  serviceMeetings?: IServiceMeeting[]
}) {
  const date = parseDate(weekStartDate)

  return serviceMeetings
    .filter(sm => {
      return isSameWeek(sm.timestamp, date)
    })
    .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
}

export function getGardenCareGroup({
  week = getWeekNumber(new Date()),
  startGroup = 0,
  groups,
}: {
  week: number
  startGroup?: number
  groups: string[]
}) {
  // startGroup - 1 for array index,
  // week - 1 for not adding current week since 1-index based
  const groupIdx = (startGroup - 1 + week - 1) % groups.length

  return groups[groupIdx]
}

export function getCleaningGroup({
  month = new Date().getMonth(), // 0-index based
  startGroup = 0,
  groups,
}: {
  month: number
  startGroup?: number
  groups: string[]
}) {
  // startGroup - 1 for array index
  const groupIdx = (startGroup - 1 + month) % groups.length

  return groups[groupIdx]
}
