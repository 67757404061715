import styled from 'styled-components'

export const StyledMenuButton = styled.button`
  width: 2.25rem;
  height: 2.25rem;

  padding: 0;

  font-size: 1.25rem;

  color: currentColor;
  background-color: hsl(0 0% 100% / 0.5);

  border: 0px solid transparent;
  border-radius: 100%;

  @media (prefers-color-scheme: dark) {
    background-color: hsl(0 0% 0% / 0.25);
  }

  &:hover {
    background-color: white;

    @media (prefers-color-scheme: dark) {
      background-color: ${props => props.theme.colors.primary.deep};
    }
  }

  &:active {
    box-shadow: 0 0 0 1px black;

    @media (prefers-color-scheme: dark) {
      box-shadow: 0 0 0 1px hsl(0 0% 100% / 0.5);
    }
  }

  &:first-child {
    margin-right: 1rem;
  }

  &[disabled] {
    opacity: 0.25;
    background-color: transparent;

    &:hover,
    &:active {
      box-shadow: none;
    }
  }

  h1 + & {
    margin-left: auto;
  }

  & + & {
    margin-left: 0.5rem;
  }
`

export const StyledAppBar = styled.section`
  display: flex;
  align-items: center;

  width: 100%;
  height: 3.5rem;

  padding: 1rem;

  font-size: 1.5rem;

  color: ${props => props.theme.colors.primary.icon};
  background-color: ${props => props.theme.colors.primary.default_border};

  @media (prefers-color-scheme: dark) {
    color: white;
    background-color: ${props => props.theme.colors.primary.icon};
  }

  @supports (padding: env(safe-area-inset-left)) {
    padding-left: calc(1rem + env(safe-area-inset-left));
    padding-right: calc(1rem + env(safe-area-inset-right));
  }

  h1 {
    font: inherit;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1rem;

    .subtitle {
      display: block;

      margin-top: 0.25rem;

      font-size: 1rem;
      font-weight: normal;

      color: #436593;

      @media (prefers-color-scheme: dark) {
        color: #fffb;
      }

      @media screen and (max-width: 320px) {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 13ex;
      }
    }
  }
`

export const StyledTabBar = styled.section`
  width: 100%;
  height: 3rem;

  position: relative;

  background-color: #ecf1f8;

  .scroll-container {
    display: flex;

    overflow-x: scroll;

    position: relative;
    padding-left: 3.25rem;
    margin-right: 1rem;

    scroll-behaviour: smooth;
    -webkit-scroll-behaviour: smooth;
    scroll-snap-type: x mandatory;

    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  @media (prefers-color-scheme: dark) {
    background-color: #1d334f;
  }

  @supports (padding: env(safe-area-inset-left)) {
    padding-left: calc(env(safe-area-inset-left));
    padding-right: calc(env(safe-area-inset-right));
  }

  .scroll-indicator {
    appearance: none;

    opacity: 0;

    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 2rem;
    height: 100%;

    position: absolute;
    top: 0;

    font-size: 1.5rem;
    line-height: 1;

    color: #28456c;
    background-color: #c7d6ea;

    cursor: pointer;

    transition: opacity 0.2s cubic-bezier(0, 0, 0.2, 1);

    @media (prefers-color-scheme: dark) {
      color: #fff;
      background-color: #335789;
    }

    @supports (padding: env(safe-area-inset-left)) {
      margin-left: calc(env(safe-area-inset-left));
      margin-right: calc(env(safe-area-inset-right));
    }

    &.scroll-indicator--left {
      left: 0;
    }

    &.scroll-indicator--right {
      right: 0;
    }

    &.visible {
      opacity: 1;
    }
  }

  button {
    display: flex;
    align-items: center;

    appearance: none;

    flex-basis: 90px;
    max-width: 22.5rem;
    height: 3rem;

    padding: 0.75rem 1rem;

    font-size: 1rem;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    color: #436593;
    background-color: transparent;

    border: none;
    border-radius: 0;

    scroll-snap-align: center;

    @media (prefers-color-scheme: dark) {
      color: #fffb;
    }

    &.active {
      color: #28456c;

      box-shadow: inset 0 -2px currentColor;

      @media (prefers-color-scheme: dark) {
        color: white;
      }
    }
  }
`

export const StyledHeader = styled.header`
  @media print {
    display: none;
  }

  @media screen {
    display: flex;
    flex-flow: column wrap;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    /* box-shadow: 0 0 18px rgba(0, 0, 0, .06), 0 6px 18px rgba(0, 0, 0, .12); */
    box-shadow: 0 0 18px rgba(40, 69, 108, 0.06),
      0 6px 18px rgba(40, 69, 108, 0.12);
  }
`

export default StyledHeader
