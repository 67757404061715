import styled from 'styled-components'

export const StyledBugReport = styled.div`
  text-align: left;

  label {
    display: block;

    width: 100%;

    margin-bottom: 0.25rem;

    font-size: 0.9rem;
    font-weight: 600;
  }

  .input-group {
    margin-bottom: 1rem;
  }
`
