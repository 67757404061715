import type { User } from '@supabase/supabase-js'
import { IFile } from '../views/Upload/data'
import {
  ICongregationRow,
  IMeetingRow,
  IMessageRow,
  INoteRow,
  ISectionRow,
  IServiceMeetingRow,
} from './supabase-interfaces.d'

export enum MEETING_TYPES {
  MIDWEEK = 'midweek',
  WEEKEND = 'weekend',
}

// type ITaskType = 'gw1' | 'gw2' | 'gw3' | 'fm1' | 'fm2' | 'fm3'

export interface ITask {
  order?: number
  type: string
  title?: string
  assigned?: string
  reader?: string
  congregation?: string
  duration?: number
  phone?: string
  speaker_host?: string
}
// export type ITask = Omit<ITaskRow, 'id' | 'congregation_uuid' | 'section'>

// export interface ISection {
//   type: string
//   title?: string
//   heading?: string
//   tasks: ITask[]
// }
export interface ISection
  extends Omit<ISectionRow, 'id' | 'congregation_uuid' | 'meeting'> {
  tasks: ITask[]
}

// export interface IMeeting {
//   type: MEETING_TYPES
//   title: string
//   date: string
//   chairman: string
//   sections: ISection[]
// }
export interface IMeeting extends IMeetingRow {
  type: MEETING_TYPES
  sections: ISection[]
}

// export interface IServiceMeeting {
//   order: string | number
//   timestamp: string
//   location: string
//   assigned: string
// }
export type IServiceMeeting = Omit<
  IServiceMeetingRow,
  'id' | 'created_at' | 'congregation_uuid'
>

// export interface INote {
//   order: string
//   text: string
// }
export type INote = Pick<INoteRow, 'order' | 'text'>

export interface IZoomLinks {
  meetings?: {
    midweek?: string
    weekend?: string
  }
  service_meetings?: {
    general?: string
  }
}

export interface ICongregation {
  uuid: string
  name: string
  etag?: string | null
  zoom_links?: IZoomLinks
  /**
   * A list of service groups of the congregation
   */
  groups?: string[]
  garden_group_start?: number
  cleaning_group_start?: number
  extra?: string
}

export interface IProgrammeCongregation
  extends Pick<
    ICongregationRow,
    | 'name'
    | 'zoom_links'
    | 'groups'
    | 'garden_group_start'
    | 'cleaning_group_start'
  > {
  zoom_links: IZoomLinks | null
}

export interface IProgramme {
  api_version: string
  // lastUpdatedAt?: Date
  /**
   * The congregation associated with the user
   */
  congregation: IProgrammeCongregation
  notes?: Pick<INoteRow, 'text' | 'order'>[]
  service_meetings?: Pick<
    IServiceMeetingRow,
    'timestamp' | 'assigned' | 'location'
  >[]
  /**
   * The meetings of the monthly programme
   */
  meetings?: IMeeting[]
  files?: IFile[]
  // extra?: ICongregationRow['extra']
  extra?: IExtraInformation
}

export interface ICongregationUpload extends Omit<ICongregation, 'uuid'> {
  zoom_links: IZoomLinks
}
export interface IProgrammeUpload extends IProgramme {
  congregation: ICongregationUpload
}

export interface IContactPerson {
  id?: string
  name: string
  phone: string
}
export interface IExtraInformation {
  contacts: IContactPerson[]
  /** Index 1. Subtract 1 to get the position in contacts array. */
  contactStart: number | null
}

export type IMessage = Omit<IMessageRow, 'created_at' | 'congregation_uuid'>

export type ICustomClaims = User['app_metadata'] & {
  congregation_uuid?: string
  user_role?: IRole
}

export type IRole = 'user' | 'manager' | 'admin'

export type IUser = User & {
  app_metadata: ICustomClaims
}
