import classNames from 'class-names'

import StyledTask from './styles'
import { ITask } from '../../../declarations/interfaces.d'
import { ReactNode } from 'react'

function renderTitle(type: string, title?: string, assigned?: string) {
  if (type !== 'song') {
    return title
  }

  const hasAssigned = assigned !== undefined

  if (hasAssigned) {
    return `Sång ${title || ''} & bön`
  }

  return `Sång ${title || ''}`
}

type TaskProps = ITask & {
  component?: ReactNode
  myName?: string
}

function Task({
  type,
  title,
  assigned,
  congregation,
  reader,
  component,
  myName,
}: TaskProps) {
  return (
    <StyledTask className={classNames(type)}>
      <span className='title'>{renderTitle(type, title, assigned)}</span>
      {assigned ? (
        <span
          className={classNames('assigned', myName === assigned && 'highlight')}
        >
          {congregation ? `${assigned} (${congregation})` : assigned}
        </span>
      ) : null}
      {reader ? (
        <span className={classNames('reader')}>
          Läsare:{' '}
          <span className={classNames(myName === reader && 'highlight')}>
            {reader}
          </span>
        </span>
      ) : null}
      {component}
    </StyledTask>
  )
}

export default Task
