import styled from 'styled-components'

export const Protip = styled.aside`
  padding: 1rem;

  line-height: 1.5rem;

  background-color: ${props => props.theme.colors.gray[95]};

  border-radius: 0.5rem;

  @media (prefers-color-scheme: dark) {
    background-color: ${props => props.theme.colors.gray.default};
  }

  &:not(:first-child) {
    margin-top: 1rem;
  }

  > strong {
    display: block;

    margin-bottom: 0.5rem;

    font-size: 1.125rem;
    font-weight: 600;

    &.danger {
      color: ${props => props.theme.colors.danger.solid};
    }
  }

  table {
    width: 100%;
    margin-top: 0.5rem;
    border-collapse: collapse;

    thead {
      font-weight: 600;
    }

    td {
      padding: 0.25rem 0.5rem;
      border: 1px solid #ccc;
    }

    tbody tr {
      background-color: white;

      @media (prefers-color-scheme: dark) {
        color: white;
        background-color: #333;
      }
    }
  }
`

const StyledItem = styled.article`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  padding: 0.75rem 1rem;

  line-height: 1.5;

  background-color: ${props => props.theme.colors.primary.default};

  border-radius: 0.5rem;

  button.action-button {
    padding: 0;

    background-color: transparent;

    border: none;

    // TODO: Add hover states (if hover media query) and enough touch area (using ::before à la Material UI)
    cursor: pointer;
  }

  &:not(:first-child),
  form:not(:first-child) & {
    margin-top: 0.5rem;
  }

  @media (prefers-color-scheme: dark) {
    /* background-color: ${props => props.theme.colors.gray.default}; */
    background-color: ${props => props.theme.colors.primary.brand_shade};

    button.action-button {
      color: white;
    }
  }
`

export const StyledItemAddButton = styled(StyledItem)`
  display: block;
  width: 100%;

  text-align: left;

  color: inherit;
  background-color: ${props => props.theme.colors.gray[95]};

  border: 0;

  @media (prefers-color-scheme: dark) {
    background-color: ${props => props.theme.colors.gray.default};
  }

  > header {
    display: flex;
    align-items: center;

    svg {
      margin-right: 0.5rem;
    }

    span {
      flex-grow: 1;

      margin: 0;
    }
  }
`

export const StyledMeeting = styled(StyledItem)`
  // Delete button
  button {
    color: ${props => props.theme.colors.danger.solid};

    @media (prefers-color-scheme: dark) {
      color: white;
    }
  }
`

export const StyledNote = styled(StyledItem)`
  .drag-handle {
    margin-right: 1rem;

    cursor: pointer;
  }

  .text {
    flex-grow: 1;
  }

  // Edit button
  button {
    margin-left: 1rem;

    color: ${props => props.theme.colors.primary.icon};

    @media (prefers-color-scheme: dark) {
      color: white;
    }
  }
`

export const StyledServiceMeeting = styled(StyledItem)`
  /* flex-direction: column; */

  .text {
    flex-grow: 1;

    .timestamp {
      display: block;

      font-weight: 600;
    }

    .by,
    .at {
      color: hsl(0 0% 0% / 0.5);

      @media (prefers-color-scheme: dark) {
        color: hsl(0 0% 100% / 0.5);
      }
    }

    .assigned,
    .location {
      font-weight: 500;
      word-wrap: break-word;
      hyphens: auto;
      -webkit-hyphens: auto;
    }
  }

  // Edit button
  button {
    /* margin-left: auto; */
    margin-left: 1rem;

    color: ${props => props.theme.colors.primary.icon};

    @media (prefers-color-scheme: dark) {
      color: white;
    }
  }
`

export const StyledServiceMeetingForm = styled(StyledItem)`
  display: block;

  padding-bottom: 1rem;

  > header {
    display: flex;
    align-items: center;

    margin-bottom: 0.5rem;

    svg {
      margin-right: 0.5rem;
    }

    p {
      flex-grow: 1;
    }
  }
`

export const StyledNoteForm = styled(StyledItem)``

export const UploadContainer = styled.section`
  position: relative;

  .showing-original & {
    background-color: blue;
  }

  .grid {
    --columns: 1;
    display: grid;
    grid-template-columns: repeat(var(--columns, 1), 1fr);
    grid-gap: 2rem;

    @media (min-width: 52rem) {
      --columns: 2;
    }

    /* > div:not(:first-child) h2 {
      margin-top: 4rem;
    } */

    /* > div footer {
      display: block;

      margin-top: 0.5rem;

      .buttons {
        margin-top: 0.5rem;

        text-align: right;

        .btn + .btn {
          margin-left: 0.5rem;
        }
      }
    } */
  }

  /* @media screen and (min-width: 768px) {
    max-width: 72vw;
  }

  @media (prefers-color-scheme: dark) {
    color: white;
    background-color: #333;
  } */

  h1 {
    margin-bottom: 3rem;

    font-size: 2.25rem;
    font-weight: 700;
    line-height: 3rem;

    .user-name-title {
      display: inline-block;

      hyphens: auto;
    }
  }

  h2 {
    margin-bottom: 1rem;

    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;

    &:not(:first-child) {
      margin-top: 4rem;
    }
  }

  h3 {
    margin-top: 2rem;
    font-size: 1.25rem;
    font-weight: 600;
    /* line-height: 1; */
  }
  h4 {
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 600;
    /* line-height: 1; */
  }

  /* h1,
  h2, */
  /* h3,
  h4,
  h5 {
    margin-bottom: 0.5em;
  } */

  /* h1 {
    font-size: 2rem;
  } */

  /* h2, */
  /* h3,
  h4,
  h5,
  h6 {
    margin-top: 1.5em;
  } */

  label {
    display: block;

    margin-top: 1rem;
    margin-bottom: 0.25rem;

    font-weight: 600;
  }

  .form {
    .input-group-button {
      position: relative;

      button {
        width: 2rem;
        height: 2rem;

        position: absolute;
        right: 0.5rem;
        top: 50%;
        transform: translateY(-50%);

        padding: 0.25rem 0.5rem;

        border-radius: 0.25rem;

        svg {
          margin: 0;
        }
      }
    }

    .form-footer {
      display: block;

      margin-top: 0.5rem;

      .buttons {
        margin-top: 0.5rem;

        text-align: right;

        .btn + .btn {
          margin-left: 0.5rem;
        }
      }
    }

    .btn {
      /* display: block;

      margin-top: 1rem; */

      & + .btn {
        margin-left: 0.5rem;
      }

      /* &.danger {
      background-color: #993334;
    }

    &.cta {
      margin-top: 3rem;
      padding: 0.75rem 1.125rem;

      background-color: #9b59b6;

      border-radius: 0.375rem;
    } */
    }
  }

  input {
    width: 100%;
  }

  /* table {
    width: 100%;
    margin-top: 0.5rem;

    thead {
      font-weight: 600;
    }

    td {
      padding: 0.25rem 0.5rem;

      border: 1px solid #ccc;
    }

    tbody tr {
      background-color: white;

      @media (prefers-color-scheme: dark) {
        color: white;
        background-color: #333;
      }
    }
  } */

  ol,
  ul {
    margin: revert;
    padding: revert;
  }
  /* li {
    display: flex;
    align-items: center;

    padding: 0.5rem 0.75rem;

    background-color: white;

    border-radius: 0.25rem;
    box-shadow: 0 0.125rem 0.125rem #0003;

    + li {
      margin-top: 0.5rem;
    }

    span.drag-handle + p {
      margin-left: 0.5rem;
    }

    p {
      flex: 2;
    }

    @media (prefers-color-scheme: dark) {
      color: white;
      background-color: #333;
    }
  } */

  button.remove {
    appearance: none;

    padding: 0.25rem 0.5rem;
    margin-left: auto;

    font: inherit;

    background-color: #c55;
    color: white;

    border: 0;
    border-radius: 0.25rem;

    &:hover,
    &:focus {
      background-color: hsl(0, 47%, 49%);
    }

    &:active {
      background-color: hsl(0, 50%, 43%);
    }
  }

  .react-datetime-picker__wrapper {
    padding: 0.33rem 0.67rem;

    background-color: white;

    border-color: #ccc;
    border-radius: 0.25rem;
    box-shadow: inset 0 0.125rem 0.125rem #0002;
  }

  .react-datetime-picker input {
    box-shadow: none;
  }

  .file-input {
    .file-name {
      display: block;

      margin: 1rem 0 0;

      font-weight: normal;
    }

    /* .btn {
      display: inline-block;

      margin-top: 0;
    } */

    input,
    input[type='file'] {
      width: 1px;
      height: 0;
      margin: 0;
      padding: 0;

      opacity: 0;
    }
  }
`

export const ContentView = styled.main`
  width: 100%;
  padding-top: 6.5rem;

  @supports (padding: env(safe-area-inset-bottom)) {
    padding-bottom: env(safe-area-inset-bottom);
  }
`
