import classNames from 'class-names'

import Markdown from 'react-markdown'
import useTranslation from 'next-translate/useTranslation'

import { Section, ServiceMeeting, FilesList } from '../../../components'
import { StyledMeeting } from '../../../components/dashboard/Meeting/styles'
import type {
  INote,
  IProgramme,
  IServiceMeeting,
} from '../../../declarations/interfaces.d'
import Banner from '../../../components/Banner/Banner'
import { getServiceMeetingId } from '../../../components/upload/ServiceMeetingsForm'

interface OtherViewProps {
  serviceMeetingsList?: IServiceMeeting[]
  notes?: INote[]
  files: IProgramme['files']
  serviceGroup: string | null
  gardenCareGroup: string | null
  cleaningGroup: string | null
  myName: string
}

function EmptyState() {
  return (
    <Banner
      icon='info-circle'
      iconType='fad'
      inline={true}
      style={{ marginTop: '2rem' }}
    >
      <h2>
        <strong>Här var det tomt!</strong>
      </h2>
      <p>
        Det verkar som att det inte fanns någon information inlagd just nu.
        Försök igen senare.
      </p>
    </Banner>
  )
}

function FilesSection({ files }: Partial<OtherViewProps>) {
  const { t } = useTranslation('views')

  if (!files) {
    return null
  }

  return (
    <>
      <Section
        type='files'
        title={t('other.documents')}
        tasks={[
          {
            type: 'documents',
            title: t('other.disaster prep'),
            component: <FilesList files={files} />,
          },
        ]}
      />
    </>
  )
}

export default function OtherView({
  serviceMeetingsList,
  notes,
  files,
  serviceGroup,
  gardenCareGroup,
  cleaningGroup,
  myName,
}: OtherViewProps): JSX.Element {
  const { t } = useTranslation('views')

  const displayWeekInformationSection =
    gardenCareGroup || serviceMeetingsList?.length
  const displayMonthInformationSection = cleaningGroup || notes?.length

  const emptyState =
    !serviceMeetingsList?.length &&
    !notes?.length &&
    !gardenCareGroup &&
    !cleaningGroup &&
    !files.length

  return (
    <StyledMeeting>
      {emptyState ? (
        <EmptyState />
      ) : (
        <>
          {displayWeekInformationSection ? (
            <Section
              type='week-info'
              title={t('other.week info')}
              tasks={[
                {
                  type: 'garden',
                  title: t('other.garden care'),
                  component: (
                    <span
                      className={classNames(
                        'assigned',
                        serviceGroup === gardenCareGroup && 'highlight',
                      )}
                    >
                      {gardenCareGroup || t('other.no group')}
                    </span>
                  ),
                },
                ...(serviceMeetingsList && serviceMeetingsList.length > 0
                  ? [
                      {
                        type: 'service-meeting',
                        title: t('other.service meetings'),
                        component: serviceMeetingsList.map(serviceMeeting => (
                          <ServiceMeeting
                            key={getServiceMeetingId(serviceMeeting)}
                            timestamp={serviceMeeting.timestamp}
                            location={serviceMeeting.location}
                            assigned={serviceMeeting.assigned}
                            myName={myName}
                          />
                        )),
                      },
                    ]
                  : []),
              ]}
            />
          ) : null}
          {displayMonthInformationSection ? (
            <Section
              type='week-info'
              title={t('other.month info')}
              tasks={[
                {
                  type: 'cleaning',
                  title: t('other.cleaning'),
                  component: (
                    <span
                      className={classNames(
                        'assigned',
                        serviceGroup === cleaningGroup && 'highlight',
                      )}
                    >
                      {cleaningGroup || t('other.no group')}
                    </span>
                  ),
                },
                ...(notes?.length
                  ? [
                      {
                        type: 'notes',
                        title: t('other.notes'),
                        component: notes.map((note, noteIdx) => (
                          <Markdown key={'note' + noteIdx}>
                            {note.text}
                          </Markdown>
                        )),
                      },
                    ]
                  : []),
              ]}
            />
          ) : null}
        </>
      )}
      {files.length > 0 ? <FilesSection files={files} /> : null}
    </StyledMeeting>
  )
}
