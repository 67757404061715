export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function isHexString(string: string) {
  try {
    const regexp = new RegExp(`[0-9a-f:]{${string.length}}`, 'g')

    return Boolean(string.match(regexp))
  } catch (err) {
    return false
  }
}

export function isBase64String(string: string) {
  try {
    const regexp = new RegExp(`[A-Za-z0-9+/=]{${string.length}}`, 'g')

    return Boolean(string.match(regexp))
  } catch (err) {
    return false
  }
}
