import { ReactNode, useRef } from 'react'
import classnames from 'class-names'

import { useDialog } from '@react-aria/dialog'

import { StyledModal, StyledModalContainer } from './styled'
import { useModal, useOverlay, usePreventScroll } from '@react-aria/overlays'
import { FocusScope } from '@react-aria/focus'

type ModalProps = {
  role?: 'dialog'
  title: string
  isOpen: boolean
  onClose(): void
  isDismissable?: boolean

  children?: ReactNode
}

export default function Modal(props: ModalProps) {
  const { title, isOpen, children } = props

  // Handle interacting outside the dialog and pressing
  // the Escape key to close the modal.
  const ref = useRef()
  const { overlayProps, underlayProps } = useOverlay(props, ref)

  // Prevent scrolling while the modal is open, and hide content
  // outside the modal from screen readers.
  usePreventScroll()
  const { modalProps } = useModal()

  // Get props for the dialog and its title
  const { dialogProps, titleProps } = useDialog(props, ref)

  return (
    <StyledModalContainer {...underlayProps}>
      {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
      <FocusScope contain restoreFocus autoFocus>
        <aside
          role='dialog'
          className={classnames('backdrop', isOpen && 'open')}
          // onClick={() => onClose()}
          // onKeyPress={() => onClose()}
        />
        <StyledModal
          {...overlayProps}
          {...dialogProps}
          {...modalProps}
          ref={ref}
        >
          <h2 {...titleProps}>{title}</h2>
          {children}
        </StyledModal>
      </FocusScope>
    </StyledModalContainer>
  )
}
