import {
  CalendarDate,
  getLocalTimeZone,
  isToday,
  parseAbsoluteToLocal,
  toCalendarDate,
} from '@internationalized/date'
import { IMeeting, MEETING_TYPES } from '../../../declarations/interfaces.d'
import { getWeekNumber, isSameWeek } from '../../../utils/date'

export function getCurrentWeekIdx(
  startWeek: number,
  endWeek: number,
  date: Date | string | number = Date.now(),
) {
  // If start week is greater than end week, week 52 is somewhere
  // If so, add 52 to current week before subtracting start week
  const hasWeek52 = startWeek > endWeek

  let currentWeek = getWeekNumber(date)
  if (hasWeek52 && currentWeek < startWeek) {
    currentWeek += 52
  }

  return currentWeek - startWeek
}

export function getMeeting({
  weekStartDate,
  meetings = [],
  meetingType = MEETING_TYPES.MIDWEEK,
}: {
  weekStartDate: CalendarDate
  meetings?: IMeeting[]
  meetingType?: MEETING_TYPES
}) {
  const meeting = meetings.find(
    _meeting =>
      isSameWeek(_meeting.date, weekStartDate) && _meeting.type === meetingType,
  )

  if (!meeting) {
    return null
  }

  return meeting
}

export function getNextMeeting({
  weekStartDate,
  meetings = [],
}: {
  weekStartDate: CalendarDate
  meetings?: IMeeting[]
}) {
  const nextMeeting = meetings.find(meeting => {
    const date = toCalendarDate(parseAbsoluteToLocal(meeting.date))

    return date.compare(weekStartDate) > 0 || isToday(date, getLocalTimeZone())
  })

  if (!nextMeeting) {
    return null
  }

  return nextMeeting
}
