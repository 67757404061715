import { HTMLProps, ReactNode } from 'react'

import Link, { LinkProps } from 'next/link'
import { useRouter } from 'next/router'

export type NavLinkProps = LinkProps &
  HTMLProps<HTMLAnchorElement> & {
    activeClassName?: string

    children?: ReactNode
  }

export default function NavLink({
  children,
  activeClassName = 'active',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref,
  ...props
}: NavLinkProps) {
  const { asPath } = useRouter()

  const childClassName = props.className || ''
  const active = asPath === props.href || asPath === props.as

  const className = active
    ? `${childClassName} ${activeClassName}`.trim()
    : childClassName

  return (
    <Link {...props}>
      <a className={className}>{children}</a>
    </Link>
  )
}
