import styled from 'styled-components'

export const StyledLoadingIndicator = styled.section`
  width: auto;

  position: absolute;
  top: 50%;
  left: 50%;

  padding: 2rem;

  text-align: center;

  background-color: white;
  box-shadow: 0 0.125rem 0.25rem #0002;

  border-radius: 0.5rem;

  transform: translate(-50%, -50%);

  @media (prefers-color-scheme: dark) {
    color: white;
    background-color: #1c1c1c;
  }

  p {
    margin-top: 1rem;
  }
`
