import styled from 'styled-components'

export const StyledMeeting = styled.div`
  .notes p + p {
    margin-top: 1rem;
  }

  @media screen {
    max-width: 24rem;

    margin: 0 auto;

    header.zoom {
      padding: 1rem 1rem 0;
      text-align: center;

      /* a {
        display: inline-block;

        padding: 0.75rem 1rem;
        color: #28456c;
        background-color: #28456c22;

        border-radius: 0.25rem;

        @media (prefers-color-scheme: dark) {
          color: white;
          background-color: #383838;
        }
      } */
    }

    & + & {
      margin-left: 1rem;
    }
  }

  @media print {
    header.zoom {
      display: none;
    }

    & + & {
      margin-top: 5pt;
    }
  }
`
